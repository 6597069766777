import React from 'react'
import Helmet from 'react-helmet'
//import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'gatsby'

export default class ShoppingFeeds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {  }
  }

  render() {
    return (
      <Layout>
        <Helmet>
            <title>WesData | Product Feeds</title>
        </Helmet>
        <section className="section" >
            <div className="container">
                <div className="content">
                    <div className="columns">
                        <div className="column is-12">
                            <h1>Product Feeds</h1>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3">
                            <div class="card">
                                <div class="card-image" style={{backgroundColor:'#406eb7',textAlign:'center',paddingTop:'1em',paddingBottom:'1em'}}>
                                    <FontAwesomeIcon icon="rss" size="7x" color="#fff"/>
                                </div>
                                <div class="card-content">
                                    <div class="media">
                                        <div class="media-content">
                                            <p class="title is-4">Feeds That Fit</p>
                                        </div>
                                    </div>

                                    <div class="content">
                                    Our approach to feed processing insures that you get a perfect fit every time.
                                    We get to know your data intimately, and work with you to accommodate its weaknesses
                                    and augment its shortcomings.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div class="card"><div class="card-content">
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="cloud-download-alt" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Acquisition</h3>
                                    <p>We insure that your product data is harvested in a timely fashion as often as you need it to be. You can send it to us or we can actively retrieve it in almost any way imaginable. Don't have a baseline feed? No problem- we can custom crawl your site for the latest product information and fill-in any missing pieces while we're at it.</p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="cogs" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Processing</h3>
                                    <p>Once we have your raw catalog in-hand, our shopping feed engine will process and format it according to the unique requirements of your preferred comparison shopping engines, affiliate networks and online marketplaces.</p>
                                    <p><strong>Custom Formatting</strong><br/>Does your catalog export have shortcomings? Are some pieces of data missing and you need to fill-in the blanks? Do you want your products to behave differently based on rules that you define? Use our rules engine to easily transform your data in a number of ways. If your needs are more complex, we can help.</p>
                                    <p><strong>Shopping Engine Optimization</strong><br/>We optimize your data to display to its best advantage based on the specific rules and requirements of each shopping partner. This includes taking advantage of features such as bottom-line and strike-through pricing, partner-specific categorization, special shopping aisles and more.</p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="project-diagram" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Distribution</h3>
                                    <p>Once your feeds have been processed and optimized, we insure that they are sent to your partners promptly and in the manner that they require. Our delivery system insures that your partners receive feeds as fast as they're processed, and throughout the day as often as you need.</p>
                                </div>
                            </div>
                            </div></div>
                        </div>
                        <div className="column is-3">
                        <div class="card">
                                <div class="card-content">
                                    <div class="media">
                                        <div class="media-content">
                                            <p class="title is-4">More Services</p>
                                        </div>
                                    </div>

                                    <div class="content">
                                        <ul>
                                            <li>
                                                <Link to="/services/partner-management">Partner Management</Link>
                                            </li>
                                            <li>
                                                <Link to="/services/roi-analysis">Feed Analytics</Link>
                                            </li>
                                            <li>
                                                <Link to="/services/feed-optimization">Feed Optimization</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </Layout>
    )
  }
}
